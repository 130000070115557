<template>
  <div class="detail">
    <div class="left">
      <!-- mediaType 文章的类型，0为默认，1表示为视频文章 -->
      <div class="header-video black" v-if="detailObj.mediaType == 1">
        <div class="mediaCover">
          <video-player :video="video"></video-player>
        </div>
      </div>
      <div class="header gray" v-if="detailObj.mediaType == 0">
        <img :src="picHead + detailObj.poster" alt="" />
        <div class="texts">
          {{ detailObj.title }}
        </div>
      </div>
      <div class="header-con">
        <div class="texts" v-if="detailObj.mediaType == 1">
          {{ detailObj.title }}
        </div>
        <div class="header-con-title">
          <div class="header-con-title-left">
            <span class="author">{{ detailObj.originAuthor }}</span>
            <span class="ml-10">{{ detailObj.createDate | dateFormate }}</span>
          </div>
          <div
            class="header-con-title-right cur-p dis-flex"
            @click="toShowDialog(detailObj.id)"
          >
            <span class="dis-flex">
              <!-- <i class="el-icon-chat-dot-square mr-5"></i> -->
              <svg
                t="1610436866327"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="1722"
                width="200"
                height="200"
              >
                <path
                  d="M310.857143 530.285714c30.2848 0 54.857143-24.572343 54.857143-54.857143s-24.572343-54.857143-54.857143-54.857143-54.857143 24.572343-54.857143 54.857143S280.572343 530.285714 310.857143 530.285714z"
                  p-id="1723"
                ></path>
                <path
                  d="M512 530.285714c30.2848 0 54.857143-24.572343 54.857143-54.857143s-24.572343-54.857143-54.857143-54.857143-54.857143 24.572343-54.857143 54.857143S481.7152 530.285714 512 530.285714z"
                  p-id="1724"
                ></path>
                <path
                  d="M713.142857 530.285714c30.2848 0 54.857143-24.572343 54.857143-54.857143s-24.572343-54.857143-54.857143-54.857143-54.857143 24.572343-54.857143 54.857143S682.858057 530.285714 713.142857 530.285714z"
                  p-id="1725"
                ></path>
                <path
                  d="M969.142857 109.714286 54.857143 109.714286c-20.214857 0-36.571429 16.374857-36.571429 36.571429l0 658.285714c0 20.214857 16.356571 36.571429 36.571429 36.571429l329.142857 0 128 182.857143 128-182.857143 329.142857 0c20.214857 0 36.571429-16.356571 36.571429-36.571429L1005.714286 146.285714C1005.714286 126.089143 989.357714 109.714286 969.142857 109.714286zM932.571429 768 601.929143 768 512 896.464457 422.072686 768 91.428571 768 91.428571 182.857143l841.142857 0L932.571429 768z"
                  p-id="1726"
                ></path>
              </svg>
              <span>{{ detailObj.commentNum }}</span>
            </span>
            <span class="ml-10 dis-flex">
              <!-- <i class="el-icon-collection mr-5"></i> -->
              <svg
                t="1610436677500"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4111"
                width="200"
                height="200"
              >
                <path
                  d="M981.4656 348.178286c-6.928457-9.142857-17.749943-14.535314-29.215086-14.535314l-292.571429 0L659.679086 36.571429c0-20.214857-16.356571-36.571429-36.571429-36.571429l-136.8576 0c-13.143771 0-25.285486 7.071086-31.786057 18.499657L236.106971 402.285714 36.571429 402.285714c-20.214857 0-36.571429 16.356571-36.571429 36.571429l0 475.428571c0 20.214857 16.356571 36.571429 36.571429 36.571429l769.393371 0c16.321829 0 30.677943-10.821486 35.178057-26.536229l146.285714-544.071314C990.571886 369.214171 988.357486 357.357714 981.4656 348.178286zM73.142857 475.428571l37.9648 0 0 402.285714L73.142857 877.714286 73.142857 475.428571zM778.3936 877.714286 184.250514 877.714286 184.250514 475.428571l73.142857 0c13.143771 0 25.285486-7.071086 31.786057-18.499657L507.536457 73.142857l78.999771 0 0 297.071543c0 20.214857 16.356571 36.571429 36.571429 36.571429l280.643657 0L778.3936 877.714286z"
                  p-id="4112"
                ></path>
              </svg>
              <span>{{ detailObj.thumbsNum }}</span>
            </span>
          </div>
        </div>
        <div class="mt-40 content" v-html="detailObj.content"></div>
      </div>
    </div>
    <div class="right">
      <div class="title">你可能还喜欢</div>
      <div class="con" v-for="(item, index) in mayLoveData" :key="item.id">
        <div
          v-if="index < 4"
          class="pb-10 cur-p pos-r"
          @click="toNewDetail(item.id)"
        >
          <div class="play-icon" v-if="item.mediaType == 1">
            <i class="el-icon-caret-right"></i>
          </div>
          <!-- <img :src="picHead + item.poster" alt="" /> -->
          <div
            :style="{
              backgroundImage: 'url(' + picHead + item.poster + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              height: '140px',
            }"
          ></div>
          <div class="texts ellipsis-2">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <div class="dialog-title">{{ hostCompany }}</div>
      <img :src="detailWxacode" alt="" />
      <div class="dialog-tips">扫一扫微信小程序码查看详情</div>
    </el-dialog>
  </div>
</template>

<script>
import _con from '../../config/config.base';
const videoPlayer = (resolve) => {
  require(['../component/video.vue'], resolve);
};
export default {
  name: 'Detail',
  components: { 'video-player': videoPlayer },
  data() {
    return {
      id: this.$route.params.id,
      detailObj: {},
      articlesParams: {
        pageNo: 1,
        pageSize: 10,
        queryType: 1, // 1,分页查询;2,list查询;3,首页推荐查询;
        navTypeId: '', // 搜索条件(文章分类)
      },
      mayLoveData: [],

      // 弹框
      dialogVisible: false,
      hostCompany: '',
      detailWxacode: '',

      // 视频
      video: {
        autoplay: false,
        sources: [],
        poster: '',
      },
    };
  },
  created() {
    this.getInit();
    this.hostCompany = localStorage.getItem('hostCompany');
  },
  computed: {
    picHead() {
      return this.$store.state.picHead;
    },
  },
  methods: {
    toNewDetail(id) {
      let routeData = this.$router.resolve({
        name: 'Detail',
        params: { id: id },
      });
      window.open(routeData.href, '_blank');
    },
    getInit() {
      // 获取文章详情
      this.$request(this.$api.getArticlesDetail + this.id).then((res) => {
        this.detailObj = res;
        document.title = this.detailObj.title;

        // 可能喜欢
        this.articlesParams.navTypeId = this.detailObj.navTypeId;
        this.$request(this.$api.getArticles, this.articlesParams).then(
          (res) => {
            if (res.success) {
              this.mayLoveData = res.datas.datas;
              this.mayLoveData = this.mayLoveData.filter(
                (item) => item.id != this.detailObj.id
              );
            }
          }
        );

        this.video.autoplay = false;
        this.video.poster = this.picHead + this.detailObj.poster;
        this.video.sources.push(this.detailObj.mediaUrl); // mtsVideoLink
      });
    },
    toShowDialog(id) {
      this.dialogVisible = true;
      this.$request(
        this.$api.getwxacode,
        {
          // adminId: '5a4b369ef62342523858a57f',
          type: 1,
          typeId: id,
        },
        'POST',
        '',
        {
          independent: true,
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      ).then((res) => {
        if (res.success) {
          console.log(res.datas);
          this.detailWxacode = this.picHead + res.datas.netPath;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  // padding-top: 10px;
  // display: flex;
  display: inline-flex;
  .left {
    // width: calc(100% - 255px);
    width: 755px;
    // 图片部分
    .black {
      background-color: #000;
    }
    .gray {
      background-color: rgba(0, 0, 0, 0.2);
    }
    .header-video {
      height: 440px;
    }
    .header {
      position: relative;

      img {
        width: 100%;
      }
      .texts {
        font-size: 28px;
        font-weight: 400;
        color: #fff;
        line-height: 40px;
        padding: 0 40px;
        position: absolute;
        bottom: 20px;
      }
    }
    // 内容区域
    .header-con {
      padding: 22px 40px;
      background: white;
      .texts {
        margin-bottom: 20px;
        font-size: 28px;
        font-weight: bold;
        line-height: 41px;
      }
      .header-con-title {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #a6a6a6;
        font-family: Arial, Hiragino Sans GB, 冬青黑, Microsoft YaHei, 微软雅黑,
          SimSun, 宋体, Helvetica, Tahoma, Arial sans-serif;
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
        .header-con-title-left {
          .author {
            color: #101010;
          }
        }
      }
      .content {
        font-size: 14px;
        /deep/p {
          line-height: 24px;
          width: 100% !important;
          font-size: 14px !important;
          span {
            font-size: 14px !important;
          }
          img {
            max-width: 100% !important;
          }
        }
        /deep/img {
          width: 100%;
        }
      }
    }
  }
  .right {
    width: 245px;
    margin-left: 10px;
    background: white;
    padding: 10px;
    box-sizing: border-box;
    color: #101010;
    .title {
      font-size: 14px;
      font-weight: bold;
      height: 20px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    img {
      height: 140px;
      width: 100%;
    }
    .texts {
      padding-top: 10px;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
}
/deep/.el-dialog {
  width: 350px;
}
/deep/.el-dialog__body {
  padding: 0 20px 30px;
  font-weight: bold;
  img {
    margin: 0 auto;
    height: 280px;
  }
}
.dialog-title {
  color: #101010;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  padding-bottom: 10px;
}
.dialog-tips {
  color: rgba(45, 43, 43, 42);
  height: 17px;
  line-height: 17px;
  text-align: center;
  font-size: 12px;
  padding-top: 15px;
}
</style>